export const paymentOptions = [
  {
    icon: "BDOLogo",
    name: "BDO",
  },
  {
    icon: "bpiLogo",
    name: "BPI",
  },
  {
    icon: "metrobankLogo",
    name: "Metrobank",
  },
  {
    icon: "UnionBankLogo",
    name: "Unionbank",
  },
]
