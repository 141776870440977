import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PaymentSelection from '../components/payment/payment-selection'

const Payment = () => (
  <Layout>
    <SEO
      title="Choose Payment Method"
      keywords={[
        `novartis`,
        `medgrocer`,
        `pulse care`,
        `entresto`,
        `sacubitril-valsartan`,
      ]}
    />

    <PaymentSelection />
  </Layout>
)

export default Payment
