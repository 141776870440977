import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import classNames from "classnames"

export const PurePaymentMethod = ({
  data,
  handleSelectChange,
  id,
  value,
  isSelected,
  children,
  icon,
}) => {
  return (
    <>
      <input
        type="radio"
        className="radio"
        name="paymentMethod"
        id={id}
        value={value}
        onChange={handleSelectChange}
      />
      <label for={id} className="has-text-centered">
        <article
          className={classNames("media p-1", {
            "has-background-light has-text-primary": isSelected,
          })}
        >
          {icon && (
            <figure className="media-left">
              <p className="image is-64x64">
                <Img fluid={data[icon].childImageSharp.fluid} />
              </p>
            </figure>
          )}
          <div className="media-content">
            <div className="content">{children}</div>
          </div>
        </article>
      </label>
    </>
  )
}

const PaymentMethod = ({
  data,
  handleSelectChange,
  name,
  id,
  value,
  isSelected,
  children,
  icon,
}) => (
  <StaticQuery
    query={graphql`
      query {
        codIcon: file(relativePath: { eq: "payment-method.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bankIcon: file(relativePath: { eq: "payment_bank-transfer.png" }) {
          childImageSharp {
            fluid(
              duotone: { highlight: "#324b5c", shadow: "#324b5c" }
              maxWidth: 100
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bankDepositIcon: file(relativePath: { eq: "payment-method.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        metrobankLogo: file(relativePath: { eq: "metrobank.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bpiLogo: file(relativePath: { eq: "bpi.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <PurePaymentMethod
        data={data}
        handleSelectChange={handleSelectChange}
        name={name}
        id={id}
        value={value}
        isSelected={isSelected}
        children={children}
        icon={icon}
      />
    )}
  />
)

export default PaymentMethod
