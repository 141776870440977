import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import RadioContainerButton from "./RadioContainerButton"

import styles from "./payment.module.scss"

const PaymentOption = ({ handleOnChange, option, state, props }) => (
  <div
    className={classNames(styles[`shippingDetails__paymentOptionActive`])}
    onClick={() => {
      handleOnChange(option.name)
    }}
  >
    <div
      className={classNames(
        "columns is-vcentered is-mobile ml-1 ml-0-mobile",
        styles["shippingDetails__paymentOptionContainer"]
      )}
    >
      <RadioContainerButton isSelected={state.bankName === option.name} />
      <div className={styles.image}>
        <Img fixed={props.data[option.icon].childImageSharp.fixed} />
      </div>
    </div>
  </div>
)

export default PaymentOption
