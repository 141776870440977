import React, { Component, Fragment } from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import classNames from "classnames"
import { withTranslation } from "react-i18next"

import PaymentMethod from "./payment-method"
import ContentFold from "../page-content/content-fold"
import StickyActionFooter from "../page-content/sticky-action-footer"

import PaymentOption from "./PaymentOption"
import { paymentOptions } from "./utils/paymentOptions"

import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

import Message from "../elements/Message"

class PurePaymentSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentMethod: "COD",
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = parseFloat(target.value)
    this.setState({
      changeFor: value,
    })
  }

  handleSelectChange = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value

    this.setState({
      paymentMethod: value,
    })
    this.props.dispatch({
      type: "SET_CART_DETAILS",
      payload: {
        ...this.props.cart,
        paymentMethod: value,
      },
    })
  }

  handleBankOptionChange = option => {
    this.setState({
      bankName: option,
    })
    this.props.dispatch({
      type: "SET_CART_DETAILS",
      payload: {
        ...this.props.cart,
        bankName: option,
      },
    })
  }

  render() {
    const { t } = this.props

    let isButtonDisabled =
      (this.state.paymentMethod === "COD" && !this.state.changeFor) ||
      (this.state.paymentMethod === "Bank Transfer/Deposit" &&
        !this.state.bankName)

    return (
      <ContentFold title={t("Payment Method")} isCentered>
        <Message color="warning">
          We may deliver same day. If you select cash-on-delivery (COD), please
          prepare your payment in advance.
        </Message>
        <PaymentMethod
          id="cod"
          value="COD"
          name="paymentMethod"
          handleSelectChange={this.handleSelectChange}
          isSelected={this.state.paymentMethod === "COD" ? true : false}
          icon="codIcon"
        >
          <p>
            <strong>{t("Cash on Delivery")}</strong>
          </p>
          {this.state.paymentMethod === "COD" && (
            <div class="field ">
              <label>
                {t("Change for")}:
                <div class="field-body">
                  <div class="field is-expanded">
                    <div class="field has-addons">
                      <p class="control">
                        <p class="button is-static">Php</p>
                      </p>
                      <p class="control is-expanded">
                        <input
                          type="number"
                          className={classNames("input")}
                          onChange={this.handleInputChange}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          )}
        </PaymentMethod>

        <PaymentMethod
          icon="bankIcon"
          id="bank_deposit"
          value="Bank Transfer/Deposit"
          name="paymentMethod"
          handleSelectChange={this.handleSelectChange}
          isSelected={
            this.state.paymentMethod === "Bank Transfer/Deposit" ? true : false
          }
        >
          <p>
            <strong>{t("Bank Deposit")}</strong>
          </p>
          {this.state.paymentMethod === "Bank Transfer/Deposit" && (
            <Fragment>
              <p className="help is-size-6">
                {t(
                  "After placing your order, our pharmacist will confirm your order and send our bank details."
                )}
              </p>
              <span>Select which bank you will deposit your payment to: </span>
              {paymentOptions.map(option => (
                <PaymentOption
                  option={option}
                  state={this.state}
                  props={this.props}
                  handleOnChange={this.handleBankOptionChange}
                />
              ))}
            </Fragment>
          )}
        </PaymentMethod>
        <StickyActionFooter>
          <div className="columns field is-grouped w-100">
            <div className="control column">
              <button
                className="button w-100"
                onClick={() => navigate("/checkout")}
              >
                {t("Back")}
              </button>
            </div>
            <div className="control column">
              <button
                className="button is-link w-100"
                onClick={() => {
                  this.props.dispatch({
                    type: "SET_CART_DETAILS",
                    payload: {
                      ...this.props.cart,
                      changeFor: this.state.changeFor,
                    },
                  })
                  navigate("/review-order")
                }}
                disabled={isButtonDisabled}
              >
                {t("Continue")}
              </button>
            </div>
          </div>
        </StickyActionFooter>
      </ContentFold>
    )
  }
}

const PaymentSelection = ({ data, props }) => {
  const { state, dispatch } = useContext(AppContext)

  return (
    <StaticQuery
      query={graphql`
        query {
          BDOLogo: file(relativePath: { eq: "bdo-logo--colored.png" }) {
            childImageSharp {
              fixed(width: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          UnionBankLogo: file(
            relativePath: { eq: "unionbank-logo--colored.png" }
          ) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          metrobankLogo: file(
            relativePath: { eq: "metrobank-logo--colored.png" }
          ) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          bpiLogo: file(relativePath: { eq: "bpi-logo--colored.png" }) {
            childImageSharp {
              fixed(width: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => (
        <ConnectedPaymentSelection
          data={data}
          {...props}
          cart={state.cart}
          dispatch={dispatch}
        />
      )}
    />
  )
}

const ConnectedPaymentSelection = withTranslation()(PurePaymentSelection)

export default PaymentSelection
